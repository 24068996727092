












































































































import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import { apiDcenter, apiDowntype } from "@/api/data_center";
@Component({
    components: {
        lsPagination,
        LsDialog,
    },
})
export default class download extends Vue {
    pager: RequestPaging = new RequestPaging();

    searchObj: any = {
        file_name: "",
        platform_name: "",
        type: "",
        status: "",
    };
    typeList: any = [];

    getLists(page?: number): void {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiDcenter,
            params: {
                ...this.searchObj,
            },
        });
    }

    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getLists(1);
    }

    async downLoad(url) {
        window.open(url, "_blank");
    }
    created() {
        apiDowntype({}).then((res) => {
            this.typeList = res;
        });
        this.getLists(1);
    }
}
